import { Component, Input } from '@angular/core';
import { NgIf, NgClass } from '@angular/common';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass],
})
export class IconButtonComponent {
  @Input() public text: string = '';
  @Input() public icon: string = 'cart';
  @Input() public background: string = 'primary';
  @Input() public textColor: string = 'white';
  @Input() public hover: string = 'secondary';
  @Input() public iconPosition: string = 'left';
  @Input() public disabled: boolean = false;
  @Input() public extraMargin: boolean = false;
}
